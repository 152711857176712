import { useEffect, useMemo, useRef } from "react";
import { select } from "d3-selection";
import { scaleLinear } from "d3-scale";
import humanize from "humanize-duration";
import { array, days, dayMonth } from "@/lib/utils";
import styled from "@emotion/styled";
import { useThemeUI } from "theme-ui";

const DAYS_LIMIT = 30;

const columns = 10;
const size = 10;

const HeatmapWraper = styled.div`
	cursor: default !important;
	transform: translateY(30px);
	display: flex;
	flex-wrap: wrap;
	width: ${size * columns + columns * 6}px;
	z-index: 1;

	.box {
		width: ${size}px;
		height: ${size}px;
		margin: 3px;
		background: ${props => props.pomo.heatmap.bg || "rgba(255, 255, 255, 0.1)"};
		border-radius: 50%;
		position: relative;

		&:hover {
			.title {
				transform: translateY(-100%) translateX(-50%) scale(1);
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.level {
		background: ${props => props.pomo.heatmap.item || "green"};
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 50%;
	}

	.title {
		transition: transform 100ms, opacity 100ms, visibility 100ms;
		visibility: hidden;
		opacity: 0;
		background: ${props => props.pomo.tip.bg || "rgba(0, 0, 0, 0.9)"};
		color: ${props => props.pomo.tip.text || "#fff"};
		position: absolute;
		top: 0;
		left: 50%;
		margin-top: -5px;
		transform: translateY(-100%) translateX(-50%) scale(0.8);
		padding: 3px 5px;
		font-size: 0.82em;
		border-radius: 3px;
		text-align: center;
		min-width: 20px;
		white-space: nowrap;
		pointer-events: none;
	}

	.pomodoros {
		> strong {
			position: relative;
		}
	}

	.day {
	}
`;

const Heatmap = ({ stats }) => {
	const {
		theme: { pomo },
	} = useThemeUI();
	const heatmap = useRef(null);
	const map = useRef(null);

	const values = useMemo(() => {
		const now = Date.now();
		const heats = array(DAYS_LIMIT).map((_, i) => i);
		const stat = stats || {};

		return heats.map(i => {
			const day = dayMonth(now - days(i));
			const values = stat[day] || { completed: 0, time: 0, target: 0 };

			return { day, ...values };
		});
	}, [stats]);

	const completedMax = useMemo(() => {
		const vals = values.map(d => d.completed);
		return Math.max(...vals);
	}, [values]);

	const opacity = useMemo(() => scaleLinear().range([0, 1]).domain([0, completedMax]), [
		completedMax,
	]);

	const title = d => {
		let result = `<div class="day">${d.day}</div>`;

		if (d.completed !== 0) {
			result += `<div class="pomodoros"><strong>${d.completed}</strong>/${d.target} pomodorus</div>`;
		}

		if (d.time !== 0) {
			result += `<div class="time">${humanize(d.time)}</div>`;
		}

		return `<div class="title">${result}</div>`;
	};

	const level = d => {
		const o = `opacity: ${opacity(d.completed)}`;
		return `<div class="level" style="${o}"></div>`;
	};

	const content = d => title(d) + level(d);

	useEffect(() => {
		heatmap.current = select(map.current);

		heatmap.current
			.selectAll(".box")
			.data(values)
			.enter()
			.append("div")
			.classed("box", true)
			.html(content);
	}, []);

	useEffect(() => {
		heatmap.current.selectAll(".box").data(values).html(content);
	}, [values]);

	return <HeatmapWraper ref={map} pomo={pomo}></HeatmapWraper>;
};

export default Heatmap;
