import { useEffect, useState, useRef } from "react";

const SIZE = 16;

const DEFAULTS = {
	max: 100,
	value: 0,
};

const Canvas = size => {
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");
	const ratio = window.devicePixelRatio || 1;

	canvas.width = size * ratio;
	canvas.height = size * ratio;
	ctx.scale(ratio, ratio);

	return canvas;
};

const Icon = () => {
	const icon = document.createElement("link");

	icon.rel = "icon";
	icon.type = "image/png";

	return icon;
};

const useFavicon = (opt = {}) => {
	const [options] = useState(() => ({ ...DEFAULTS, ...opt }));
	const icon = useRef(null);
	const canvas = useRef(null);

	useEffect(() => {
		icon.current = Icon();
		canvas.current = Canvas(SIZE);
	}, []);

	const attach = () => {
		const icons = document.querySelectorAll("link[rel=icon]");
		icons.forEach(icon => icon.setAttribute("rel", "prev-icon"));
		document.head.append(icon.current);
	};

	const drawPie = ({ max = 100, value = 100, width = 1, fill = "transparent", padding = 1 }) => {
		const ctx = canvas.current.getContext("2d");

		const center = SIZE / 2;
		const progress = (360 / max) * value;
		const startAngle = (Math.PI / 180) * 270;
		const endAngle = (Math.PI / 180) * (270 + progress);

		ctx.beginPath();
		ctx.moveTo(center, center);
		ctx.arc(center, center, center - padding, startAngle, endAngle, false);
		ctx.lineTo(center, center);
		ctx.strokeStyle = fill;
		ctx.fillStyle = fill;
		ctx.lineWidth = width;
		ctx.closePath();
		ctx.stroke();
		ctx.fill();
	};

	const draw = (opt = {}) => {
		const { max, value, fill } = { ...options, ...opt };

		const ctx = canvas.current.getContext("2d");

		ctx.clearRect(0, 0, SIZE, SIZE);

		drawPie({ fill: "#292f3d", padding: 0.5 });
		drawPie({ max, value, fill });

		icon.current.href = canvas.current.toDataURL();
	};

	return {
		attach,
		draw,
	};
};

export default useFavicon;
