import { useMemo } from "react";
import { zeroify } from "@/lib/utils";
import { Box, useThemeUI } from "theme-ui";

const Clock = ({ elapsed, pauses, paused }) => {
	const {
		theme: { pomo },
	} = useThemeUI();
	const clock = useMemo(() => {
		const left = Math.floor(elapsed / 1000);
		const seconds = left % 60;
		const minutes = (left - seconds) / 60;

		return { seconds, minutes };
	}, [elapsed]);

	const pause = useMemo(() => {
		const left = Math.floor(pauses / 1000);
		const seconds = left % 60;
		const minutes = (left - seconds) / 60;

		return { seconds, minutes };
	}, [pauses]);

	const pauseClock = useMemo(() => {
		const { minutes } = pause;
		const seconds = zeroify(pause.seconds);

		if (minutes === 0) return seconds;
		return `${minutes}:${seconds}`;
	}, [pause]);

	return (
		<Box
			sx={{
				position: "relative",
				display: "inline-block",
				marginBottom: "20px",
				zIndex: "1",
			}}>
			<Box
				sx={{
					transition: "opacity 200ms",
					fontSize: "4.5rem",
					lineHeight: "1",
					color: pomo.clock.min,
				}}>
				{zeroify(clock.minutes)}
			</Box>
			<Box
				sx={{
					transition: "opacity 200ms",
					fontSize: "1.25rem",
					lineHeight: "1",
					opacity: "0.6",
					position: "absolute",
					top: "5px",
					right: "-23px",
					color: pomo.clock.sec,
				}}>
				{zeroify(clock.seconds)}
			</Box>
			<Box
				sx={{
					transition: "opacity 300ms, transform 300ms, bottom 300ms",
					bottom: "5px",
					transform:
						pause.minutes !== 0
							? "translateX(3px) translateY(1px) scale(0.75)"
							: "translateX(9px)",
					position: "absolute",
					whiteSpace: "nowrap",
					opacity: pauses === 0 ? 0 : paused ? 1 : 0.3,
					left: "100%",
					color: pomo.clock.pause,
				}}>
				{pauseClock}
			</Box>
		</Box>
	);
};

export default Clock;
