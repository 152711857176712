import { useRef, useEffect } from "react";
import "d3-transition";
import { select } from "d3-selection";
import { interpolate } from "d3-interpolate";
import { pie, arc } from "d3-shape";
import { Box, useThemeUI } from "theme-ui";

const Process = ({ value, max, isBreak }) => {
	const {
		theme: { pomo },
	} = useThemeUI();
	const svg = useRef(null);
	const width = 500;
	const height = 500;
	const weight = 4;
	const viewBox = `0 0 ${width} ${height}`;
	const radius = Math.min(width, height) / 2;
	const values = [value, max - value || 1];
	const root = useRef(0);

	const currentPie = pie()
		.sort(null)
		.value(d => d);

	const currentArc = arc()
		.outerRadius(radius)
		.innerRadius(radius - weight)
		.padAngle(0.03)
		.cornerRadius(weight);

	useEffect(() => {
		root.current = select(svg.current)
			.append("g")
			.attr("transform", `translate(${width / 2}, ${height / 2})`);

		root.current
			.selectAll("path")
			.data(currentPie(values))
			.enter()
			.append("path")
			.attr("d", currentArc)
			.classed("value", d => d.index === 0)
			.classed("bar", d => d.index !== 0);
	}, []);

	useEffect(() => {
		const arcTween = function (angle) {
			const i = interpolate(0, angle);
			//anglooo = i(0);
			return t => currentArc(i(t));
		};

		const update = () => {
			root.current
				.selectAll("path")
				.data(currentPie(values))
				.transition()
				.duration(200)
				.attrTween("d", d => {
					return arcTween(d);
				})
				.style("fill", d => d?.data?.color);
		};

		update();

		//document.addEventListener("visibilitychange", update);

		//return () => document.removeEventListener("visibilitychange", update);
	}, values);

	return (
		<Box
			as="svg"
			sx={{
				overflow: "hidden",
				position: "absolute",
				top: "50%",
				left: "50%",
				width: "420px",
				height: "420px",
				transform: "translateX(-50%) translateY(-50%)",

				".value": {
					transition: "fill 200ms 200ms",
					fill: (isBreak ? pomo.process.break : pomo.process.value) || "#e4582b",
				},

				".bar": {
					fill: "transparent",
				},
			}}
			viewBox={viewBox}
			ref={svg}></Box>
	);
};

export default Process;
