import { useEffect, useState } from "react";
import humanize from "humanize-duration";
import useFavicon from "@/hooks/useFavicon";
import useFocus from "@/hooks/useFocus";
import { reachGoal, zeroify } from "@/lib/utils";
import Timer from "./components/Timer";
import Settings from "./components/Settings";
import { Box, ThemeProvider } from "theme-ui";
import theme from "./theme";

const App = () => {
	const focus = useFocus();
	const { draw, attach } = useFavicon();
	const [showSettings, setShowSettings] = useState(false);

	const drawFavicon = () => {
		let fill = "#97ce28";

		if (focus.isWork) fill = "#e4582b";
		if (focus.isPaused) fill = "#7683a2";

		draw({
			value: focus.interval,
			max: focus.duration,
			fill,
		});
	};

	const updateTitle = () => {
		const { elapsed } = focus;
		const left = Math.floor(elapsed / 1000);
		const seconds = zeroify(left % 60);
		const minutes = zeroify((left - seconds) / 60);

		if (elapsed > 0) {
			document.title = `${minutes}:${seconds} - Pomodorius`;
		} else {
			document.title = "Pomodorius";
		}
	};

	useEffect(() => {
		attach();
		drawFavicon();

		focus.start();

		focus.on("finish", ({ type, duration }) => {
			reachGoal(`pomodoro.${type}`, {
				duration: humanize(duration),
			});
		});

		focus.on("daily", () => {
			const stats = focus.statistics();

			reachGoal("daily", {
				...stats,
				time: humanize(stats.time),
			});
		});
		//focus.play();
	}, []);

	useEffect(() => {
		focus.save();
		drawFavicon();
		updateTitle();
	}, [focus.elapsed]);

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					position: "relative",
					maxWidth: "480px",
					margin: "0 auto",
					flexDirection: "column",
					width: "100%",
					height: "100%",
					display: "flex",
				}}>
				<Timer focus={focus} onToggleSettings={() => setShowSettings(!showSettings)} />
				{showSettings && (
					<Settings
						focus={focus}
						options={focus.options}
						change={focus.setOptions}
						reset={focus.reset}
						restore={focus.restore}
						isOpen={showSettings}
						onClose={() => setShowSettings(false)}
					/>
				)}
			</Box>
		</ThemeProvider>
	);
};

export default App;

