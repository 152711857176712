import { dark } from "@theme-ui/presets";

const theme = {
	...dark,
	colors: {
		...dark.colors,
		background: "#282c34",
		text: "#abb2bf",
		primary: "#d56c6c",
		secondary: "#af7850",
	},
	pomo: {
		process: {
			value: "#d56c6c",
			break: "#98c379",
		},
		target: {
			item: "rgba(201, 209, 209, .2)",
			finished: "#58afef",
			extra: "#a3d4f5",
			skipped: "rgba(110, 188, 240, .5)",
		},
		heatmap: {
			item: "#c678dd",
			bg: "rgba(255, 255, 255, 0.1)",
		},
		clock: {
			min: "#abb2bf",
			sec: "#abb2bf",
			pause: "#b19a66",
		},
		btn: {
			main: "rgba(255,255,255,.25)",
			skip: "rgba(255,255,255,.25)",
			stop: "rgba(255,255,255,.25)",
			settings: "rgba(255,255,255,.25)",
		},
		tip: {
			bg: "rgba(0,0,0,.8)",
			text: "#fff",
		},
	},
};


export default theme;
