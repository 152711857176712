import { useRef, useEffect, useMemo, useCallback } from "react";
import { select } from "d3-selection";
import { interpolate } from "d3-interpolate";
import { pie, arc } from "d3-shape";
import { Box, useThemeUI } from "theme-ui";

const Target = ({ goal, completed }) => {
	const {
		theme: { pomo },
	} = useThemeUI();
	const svg = useRef(null);
	const root = useRef(null);
	const width = 500;
	const height = 500;
	const weight = 4;
	const angle = useRef(0);

	const viewBox = `0 0 ${width} ${height}`;
	const radius = Math.min(width, height) / 2;

	const values = useMemo(() => {
		const amount = completed.length;
		const g = amount >= goal ? amount : goal;
		const items = new Array(g).fill();

		return items.map((item, index) => {
			const finished = index < amount;
			const extra = finished && index >= g;
			const skipped = !!completed[index] && completed[index].skipped;

			return {
				value: 1,
				finished,
				extra,
				skipped,
			};
		});
	}, [goal, completed]);

	const currentPie = useCallback(
		pie()
			.sort(null)
			.value((d) => d.value),
		[]
	);

	const currentArc = useCallback(
		arc()
			.outerRadius(radius)
			.innerRadius(radius - weight)
			.padAngle(0.03)
			.cornerRadius(weight),
		[]
	);

	useEffect(() => {
		const el = select(svg.current);

		el.html("");

		root.current = el
			.append("g")
			.attr("transform", `translate(${width / 2}, ${height / 2})`);

		root.current
			.selectAll("path")
			.data(currentPie(values))
			.enter()
			.append("path")
			.attr("d", currentArc)
			.classed("item", true)
			.classed("finished", (d) => d.data.finished)
			.classed("extra", (d) => d.data.extra)
			.classed("skipped", (d) => d.data.skipped)
			.each(function (d) {
				angle.current = d;
			});
	}, [goal]);

	useEffect(() => {
		const update = () => {
			const all = root.current
				.selectAll("path")
				.data(currentPie(values))
				.classed("item", true)
				.classed("finished", (d) => d.data.finished)
				.classed("extra", (d) => d.data.extra)
				.classed("skipped", (d) => d.data.skipped);

			const recent = all
				.enter()
				.append("path")
				.classed("item", true)
				.each((d) => {
					angle.current = d;
				});

			const arcTween = function (a) {
				const i = interpolate(0, a);
				//angle.current = i(0);
				return (t) => currentArc(i(t));
			};

			all.transition().duration(200).attrTween("d", arcTween);

			all.exit().remove();

			recent
				.transition()
				.delay(200)
				.duration(400)
				.attrTween("d", arcTween);
		};

		update();

		document.addEventListener("visibilitychange", update);

		return () => document.removeEventListener("visibilitychange", update);
	}, [values]);

	return (
		<Box
			as="svg"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				width: "440px",
				height: "440px",
				transform: "translateX(-50%) translateY(-50%)",
				overflow: "hidden",

				".item": {
					fill: pomo.target.item || "#000",
				},

				".finished": {
					fill: pomo.target.finished || "#39b6eb",
				},

				".extra": {
					fill: pomo.target.extra || "#6fcaf1",
				},

				".skipped": {
					fill: pomo.target.skipped || "rgba(57, 182, 235, .5)",
				},
			}}
			viewBox={viewBox}
			ref={svg}
			pomo={pomo}
		></Box>
	);
};
export default Target;
