import styled from "@emotion/styled";
import { useThemeUI, Box } from "theme-ui";

const PauseIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
		<path d="M9 16h2V8H9v8zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-4h2V8h-2v8z" />
	</svg>
);

const PlayIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
		<path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
	</svg>
);

const SkipIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
		<path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" />
	</svg>
);

const StopIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
		<path d="M6 6h12v12H6z" />
	</svg>
);

const SettingsIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
		<path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
	</svg>
);

const Tip = styled.div`
	transition: transform 100ms, opacity 100ms, visibility 100ms;
	visibility: hidden;
	opacity: 0;
	background: rgba(0, 0, 0, 0.9);
	position: absolute;
	top: 0;
	left: 50%;
	margin-top: -5px;
	transform: translateY(-100%) translateX(-50%) scale(0.8);
	padding: 3px 5px;
	font-size: 0.82em;
	border-radius: 3px;
	text-align: center;
	min-width: 20px;
	white-space: nowrap;
	pointer-events: none;
	color: #fff;
`;

const Button = styled(Box)`
	position: absolute;
	cursor: pointer;

	svg {
		width: 100%;
		height: 100%;
		opacity: 0.6;
		fill: currentColor;
	}

	&:hover {
		${Tip} {
			transform: translateY(-100%) translateX(-50%) scale(1);
			visibility: visible;
			opacity: 1;
		}
	}
`;

const Controls = ({ focus, handleSettings }) => {
	const {
		theme: { pomo },
	} = useThemeUI();
	const isPLaying = focus.isActive && !focus.isPaused;

	const buttonText = isPLaying ? "Pause" : focus.isPaused ? "Resume" : "Start";

	const stop = () => {
		if (window.confirm("Current timer will be stopped.")) {
			focus.stop();
		}
	};

	const skip = () => {
		if (window.confirm("Current timer will be skipped.")) {
			focus.skip();
		}
	};

	const buttonAction = () =>
		isPLaying ? focus.pause() : focus.isPaused ? focus.unpause() : focus.play();

	return (
		<Box
			sx={{
				overflow: "hidden",
				position: "absolute",
				top: "50%",
				left: "50%",
				width: "420px",
				height: "420px",
				transform: "translateX(-50%) translateY(-50%)",
			}}>
			<Button
				sx={{
					width: "60px",
					height: "60px",
					top: "100px",
					left: "50%",
					transform: "translate(-50%, -50%)",
					color: pomo.btn.main,
				}}
				onClick={buttonAction}>
				<Tip>{buttonText}</Tip>
				{isPLaying ? <PauseIcon /> : <PlayIcon />}
			</Button>
			<Button
				sx={{
					width: "40px",
					height: "40px",
					top: "100px",
					left: "50%",
					transform: "translate(-100px, -50%)",
					color: pomo.btn.skip,
				}}
				onClick={skip}>
				<Tip>Skip</Tip>
				<SkipIcon />
			</Button>
			<Button
				sx={{
					width: "40px",
					height: "40px",
					top: "100px",
					left: "50%",
					transform: "translate(60px, -50%)",
					color: pomo.btn.stop,
				}}
				onClick={stop}>
				<Tip>Stop</Tip>
				<StopIcon />
			</Button>
			<Button
				sx={{
					width: "30px",
					height: "30px",
					bottom: "30px",
					left: "50%",
					transform: "translate(-50%, 0)",
					color: pomo.btn.settings,
				}}
				onClick={handleSettings}>
				<Tip>Settings</Tip>
				<SettingsIcon />
			</Button>
		</Box>
	);
};

export default Controls;
