import { useState, useEffect } from "react";
import Clock from "./Clock";
import Process from "./Process";
import Target from "./Target";
import Heatmap from "./Heatmap";
import Controls from "./Controls";
import { Box } from "theme-ui";

const Timer = ({ focus, onToggleSettings }) => {
	const [stats, setStats] = useState([]);

	useEffect(() => {
		const loadStats = () => {
			const stat = JSON.parse(localStorage.getItem("statistics"));
			setStats(stat);
		};

		focus.on("update", loadStats);
	}, [focus]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				borderLeft: "25px solid transparent",
				borderRight: "25px solid transparent",
				borderTop: "37px solid transparent",
				flexGrow: "1",
			}}>
			<Clock elapsed={focus.elapsed} paused={focus.isPaused} pauses={focus.pauses} />

			<Process
				value={focus.interval}
				max={focus.duration}
				isBreak={focus.isLong || focus.isShort}
			/>

			<Heatmap stats={stats} />

			<Target goal={focus.target} completed={focus.completed} />

			<Controls focus={focus} handleSettings={onToggleSettings} />
		</Box>
	);
};

export default Timer;
