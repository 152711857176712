import { useState } from "react";
import Focus from "@/lib";

const useFocus = () => {
	const [r, setR] = useState(0);
	const [focus] = useState(() => Focus.load(() => setR(Math.random())));

	return focus;
};

export default useFocus;
