import { useState } from "react";
import { DEFAULT_TYPE, LONG_TYPE, SHORT_TYPE } from "@/lib";
import { minutes } from "@/lib/utils";
import { Select, Checkbox, Box, Button, Close, Heading, Label } from "theme-ui";

const Field = ({ label, children }) => {
	return (
		<Box sx={{ px: 3, py: 2, mb: 3 }}>
			{label && <Label mb={2}>{label}</Label>}
			<Box>{children}</Box>
		</Box>
	);
};

const CheckInput = ({ label, checked, onChange }) => (
	<Box as="label" sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
		<Box>
			<Checkbox checked={checked} onChange={() => onChange(!checked)} />
		</Box>
		{label}
	</Box>
);

const NumSelect = ({ min, max, steps = 1, formatter, value, onChange }) => {
	return (
		<Select
			onChange={e => onChange(parseInt(e.target.value))}
			value={value}
			sx={{ option: { color: "#000" } }}>
			{Array((max - min) / steps + 1)
				.fill(1)
				.map((n, i) => min + i * steps)
				.map(i => (
					<option key={i} value={i}>
						{formatter(i)}
					</option>
				))}
		</Select>
	);
};

const SettingsDrawer = ({ focus, isOpen, onClose }) => {
	const options = focus.options;

	const [notifications, setNotification] = useState({
		pending: false,
		status: null,
	});

	const notificationPerms = value => {
		if (!value || notifications.pending) return;

		setNotification({ pending: true });

		const req = Notification.requestPermission();

		req.then(status => {
			setNotification({
				pending: false,
				status,
			});
		});
	};

	const restoreDefault = () => {
		if (window.confirm("Reset settings to default values?")) {
			focus.restore();
		}
	};

	const resetSession = () => {
		if (window.confirm("Today's completed pomodoros will be reset.")) {
			focus.reset();
		}
	};

	return (
		<Box
			sx={{
				width: "300px",
				height: "100vh",
				overflowX: "hidden",
				overflowY: "auto",
				position: "fixed",
				right: "0",
				top: "0",
				bottom: "0",
				boxShadow: `0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)`,
			}}>
			<Box sx={{ display: "flex", justifyContent: "space-between", p: 3 }}>
				<Heading as="h3">Settings</Heading>
				<Close onClick={onClose} />
			</Box>
			<Field label="Daily target">
				<NumSelect
					min={1}
					max={50}
					value={options.target}
					formatter={n => `${n} pomodoro`}
					onChange={v => focus.changeOption({ target: v })}
				/>
			</Field>
			<Field label="Work interval">
				<NumSelect
					min={minutes(1)}
					max={minutes(60)}
					steps={minutes(1)}
					value={options.durations[DEFAULT_TYPE]}
					formatter={v => `${v / 1000 / 60} minutes`}
					onChange={v => focus.changeOption({ durations: { [DEFAULT_TYPE]: v } })}
				/>
			</Field>
			<Field label="Short break">
				<NumSelect
					min={minutes(1)}
					max={minutes(60)}
					steps={minutes(1)}
					value={options.durations[SHORT_TYPE]}
					formatter={v => `${v / 1000 / 60} minutes`}
					onChange={v => focus.changeOption({ durations: { [SHORT_TYPE]: v } })}
				/>
			</Field>
			<Field label="Long break">
				<NumSelect
					min={minutes(1)}
					max={minutes(60)}
					steps={minutes(1)}
					value={options.durations[LONG_TYPE]}
					formatter={v => `${v / 1000 / 60} minutes`}
					onChange={v => focus.changeOption({ durations: { [LONG_TYPE]: v } })}
				/>
			</Field>
			<Field label="Long break after">
				<NumSelect
					min={0}
					max={50}
					steps={1}
					value={options.longAfter}
					formatter={v => `${v} pomodoro`}
					onChange={v => focus.changeOption({ longAfter: v })}
				/>
			</Field>
			<Field>
				<CheckInput
					label="Auto-start timer"
					checked={options.auto}
					onChange={v => focus.changeOption({ auto: v })}
				/>
			</Field>
			<Field>
				<CheckInput
					label="Notifications"
					checked={options.notifications}
					help={
						notifications.status === "denied"
							? "Permissions denied"
							: notifications.status !== "granted"
							? "Requires user permissions"
							: null
					}
					onChange={v => {
						focus.changeOption({ notifications: v });
						notificationPerms(v);
					}}
				/>
			</Field>
			<Field>
				<CheckInput
					label="Sound alerts"
					checked={options.sounds}
					onChange={v => focus.changeOption({ sounds: v })}
				/>
			</Field>
			<Field>
				<Button variant="secondary" onClick={restoreDefault}>
					Restore defaults
				</Button>
			</Field>
			<Field>
				<Button onClick={resetSession}>Reset current session</Button>
			</Field>
		</Box>
	);
};

export default SettingsDrawer;
